/* Footer */
/* Footer */
.section-footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: #222222;
  color: #ffffff;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.section-footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Open Sans", Arial, serif;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #ffffff;
  padding-top: 50px;
  padding-bottom: 25px;
}

.section-footer-container h4 {
  text-align: left;
  margin-bottom: 5px;
  padding-bottom: 7px;
  position: relative;
  border-bottom: 2px solid #333333;
  width: 75%;
}

.section-footer-container h4:before {
  position: absolute;
  background: white;
  height: 2px;
  content: '';
  width: 50px;
  bottom: -2px;
  left: 0;
}
.contactInfo {
  text-align: left;
  margin-bottom: 5px;
  padding-bottom: 7px;
  position: relative;
  border-bottom: 2px solid #333333;
  width: 75%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.copyrightInfoContainer {
  display: flex;
  flex-direction: row;
  width: 75%;
  padding-top: 20px;
}
.copyrightTextLeft {
  width: 50%;
  text-align: left;
}
.copyrightTextRight {
  width: 50%;
  text-align: right;
}
/* DYNAMIC RESIZING */
@media (max-width: 950px) {
.section-footer-container {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
} 

