.animatedPage {
  text-align: center;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

