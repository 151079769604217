* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

html {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}

main {
  text-align: center;
  color: #304365;
}

body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  min-width:320px;        /* Suppose you want minimum width of 1000px */
  width: auto !important;  /* Firefox will set width as auto */
  width:320px;     
}

:root {
  
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  background-color: white;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --color-gray-100: #f4f3f1;
  --color-gray-200: #dddbd8;
  --color-gray-300: #ccc9c6;
  --color-gray-400: #aeaba7;
  --color-gray-500: #8a8784;
  --color-gray-600: #656360;
  --color-gray-700: #4b4a47;
  --color-gray-800: #31302e;
  --color-gray-900: #1f1d1b;

  --color-primary-100: #fcf3e1;
  --color-primary-200: #fceccd;
  --color-primary-300: #fae1af;
  --color-primary-400: #fbd997;
  --color-primary-500: #ffd37c;
  --color-primary-600: #f9c762;
  --color-primary-700: #fbc14d;
  --color-primary-800: #fab833;
  --color-primary-900: #f6ad1b;
}

button {
  font-size: 28px;
  text-decoration: none;
  color: white;
  background-color: #00A686;
  border: none;
  border-radius: 60px;
  padding: 24px 60px;
  margin: 0 10px;
}

button:hover {
  background-color: #304365;
}

button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

