/* Dashboard Page */
.dashboard-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.dashboard-container-row {
  display: flex;
  flex-direction: row;
  /* justify-content: center;  */
  justify-content: flex-start; 
  flex-wrap: nowrap; /* Wrap rows if necessary */
  width: 100%;
  gap: 20px; /* Add some gap between cards */
}

/* Dashboard Cards */
.dashboard-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0; /* Adjust vertical margin for mobile spacing */
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dashboard-card-container-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* Ensure buttons span the full width */
}

.dashboard-card-container .MuiButton-root {
  margin-top: 10px; /* Space between buttons */
}
